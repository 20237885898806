/** @format */

// theme
import { colors } from 'src/styles/theme';

import { addMeasure, parseTypography } from 'src/utils/style';
import { fonts } from 'src/styles/typography';

interface Other {
  typo: string;
  size: string;
  color: string;
  measure: string;
  center: boolean;
  bold: boolean;
  opacity: number;
  uppercase: boolean;
  lineHeight: number | string;
  transition: string;
  whiteSpace: string;
  wordBreak: string;
  fontStyle: string;
  decoration: string;
  hoverColor: string;
  textAlign: string;
  fontFamily: string;
}

const other = ({
  typo,
  size,
  color,
  measure,
  center,
  bold,
  opacity,
  uppercase,
  lineHeight,
  transition,
  whiteSpace,
  wordBreak,
  fontStyle,
  decoration,
  hoverColor,
  textAlign,
  fontFamily
}: Other): string => `
  ${typo ? parseTypography(typo) : ''}

  ${size ? `font-size: ${addMeasure(size, measure)};` : ''}
  ${color ? `color: ${colors[color] ?? color};` : ''}
  ${bold ? `
    font-weight: bold;
    font-family: ${fonts.bold.name}, sans-serif;
  ` : ''}
  ${opacity ? `opacity: ${opacity};` : ''}
  ${uppercase ? 'text-transform: uppercase;' : ''}
  ${transition ? `transition: ${transition};` : ''}
  ${whiteSpace ? `white-space: ${whiteSpace};` : ''}
  ${wordBreak ? `word-break: ${wordBreak};` : ''}
  ${fontStyle ? `font-style: ${fontStyle};` : ''}
  ${decoration ? `text-decoration: ${decoration};` : ''}
  ${textAlign ? `text-align: ${textAlign};` : ''}
  ${fontFamily ? `font-family: ${fontFamily};` : ''}

  display: block; 

  ${
    lineHeight
      ? `
        line-height: ${addMeasure(lineHeight, measure)};
        `
      : ''
  }
  ${
    center
      ? `
        text-align: center;
      `
      : ''
  }

  &:hover {
    color: ${colors[hoverColor] ?? hoverColor};
  }
`;

export default other;
