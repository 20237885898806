import logo from 'src/assets/icons/logo.svg';
import about from 'src/assets/icons/about.svg';
import map from 'src/assets/icons/map.svg';
import gagatLogo from 'src/assets/icons/gagatLogo.svg';
import hooch from 'src/assets/icons/hooch.svg';
import close from 'src/assets/icons/close.svg';
import expand from 'src/assets/icons/expand.svg';
import home from 'src/assets/icons/home.svg';
import fb from 'src/assets/icons/fb.svg';
import insta from 'src/assets/icons/insta.svg';
import checkboxCheck from 'src/assets/icons/checkboxCheck.svg';
import burger from 'src/assets/icons/burger.svg';
import left from 'src/assets/icons/left.svg';
import right from 'src/assets/icons/right.svg';
import loading from 'src/assets/icons/loading.svg';
import company from 'src/assets/icons/company.svg';
import location from 'src/assets/icons/location.svg';
import phone from 'src/assets/icons/phone.svg';
import smartphone from 'src/assets/icons/smartphone.svg';
import email from 'src/assets/icons/email.svg';
import logoLight from 'src/assets/icons/logoLight.svg';

const icons: any = {
  logo,
  about,
  map,
  gagatLogo,
  hooch,
  expand,
  close,
  home,
  location,
  phone,
  fb,
  insta,
  checkboxCheck,
  burger,
  left,
  right,
  loading,
  company,
  smartphone,
  email,
  logoLight
};

export default icons;
