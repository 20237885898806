import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import { View, Header, Footer, Image, Text, Modal, Icon } from 'src/components';

// assets
import homeBg from 'src/assets/images/homeBg.jpg';

import { getProducts } from '../Products';

// wine kenats
import k1 from 'src/assets/images/wine/kenats/k1.png';
import k2 from 'src/assets/images/wine/kenats/k2.png';
import k3 from 'src/assets/images/wine/kenats/k3.png';
import k4 from 'src/assets/images/wine/kenats/k4.png';
import k5 from 'src/assets/images/wine/kenats/k5.png';
import k6 from 'src/assets/images/wine/kenats/k6.png';
import k7 from 'src/assets/images/wine/kenats/k7.png';
import k8 from 'src/assets/images/wine/kenats/k8.png';
import k9 from 'src/assets/images/wine/kenats/k9.png';
import k10 from 'src/assets/images/wine/kenats/k10.png';
import k11 from 'src/assets/images/wine/kenats/k11.png';
import k12 from 'src/assets/images/wine/kenats/k12.png';
import k13 from 'src/assets/images/wine/kenats/k13.png';
import k14 from 'src/assets/images/wine/kenats/k14.png';
import k15 from 'src/assets/images/wine/kenats/k15.png';
import k16 from 'src/assets/images/wine/kenats/k16.png';
import k17 from 'src/assets/images/wine/kenats/k17.png';
import k18 from 'src/assets/images/wine/kenats/k18.png';

// wine gagat
import g1 from 'src/assets/images/wine/gagat/g1.png';
import g2 from 'src/assets/images/wine/gagat/g2.png';
import g3 from 'src/assets/images/wine/gagat/g3.png';
import g4 from 'src/assets/images/wine/gagat/g4.png';
import g5 from 'src/assets/images/wine/gagat/g5.png';
import g6 from 'src/assets/images/wine/gagat/g6.png';
import g7 from 'src/assets/images/wine/gagat/g7.png';
import g8 from 'src/assets/images/wine/gagat/g8.png';
import g9 from 'src/assets/images/wine/gagat/g9.png';
import g10 from 'src/assets/images/wine/gagat/g10.png';
import g11 from 'src/assets/images/wine/gagat/g11.png';
import g12 from 'src/assets/images/wine/gagat/g12.png';
import g13 from 'src/assets/images/wine/gagat/g13.png';
import g14 from 'src/assets/images/wine/gagat/g14.png';
import g15 from 'src/assets/images/wine/gagat/g15.png';
import g16 from 'src/assets/images/wine/gagat/g16.png';
import g17 from 'src/assets/images/wine/gagat/g17.png';
import g18 from 'src/assets/images/wine/gagat/g18.png';

// vodka aged
import a1 from 'src/assets/images/vodka/aged/a1.png';
import a2 from 'src/assets/images/vodka/aged/a2.png';
import a3 from 'src/assets/images/vodka/aged/a3.png';
import a4 from 'src/assets/images/vodka/aged/a4.png';
import a5 from 'src/assets/images/vodka/aged/a5.png';
import a6 from 'src/assets/images/vodka/aged/a6.png';

// vodka traditional
import t1 from 'src/assets/images/vodka/traditional/t1.png';
import t2 from 'src/assets/images/vodka/traditional/t2.png';
import t3 from 'src/assets/images/vodka/traditional/t3.png';
import t4 from 'src/assets/images/vodka/traditional/t4.png';
import t5 from 'src/assets/images/vodka/traditional/t5.png';
import t6 from 'src/assets/images/vodka/traditional/t6.png';

// canned food canned vegetables
import ba1 from 'src/assets/images/canned-food/canned-vegetables/ba1.jpg';
import ba2 from 'src/assets/images/canned-food/canned-vegetables/ba2.jpg';
import ba3 from 'src/assets/images/canned-food/canned-vegetables/ba3.jpg';
import ba4 from 'src/assets/images/canned-food/canned-vegetables/ba4.jpg';
import ba5 from 'src/assets/images/canned-food/canned-vegetables/ba5.jpg';
import ba6 from 'src/assets/images/canned-food/canned-vegetables/ba6.jpg';
import ba7 from 'src/assets/images/canned-food/canned-vegetables/ba7.jpg';
import ba8 from 'src/assets/images/canned-food/canned-vegetables/ba8.jpg';
import ba9 from 'src/assets/images/canned-food/canned-vegetables/ba9.jpg';
import ba10 from 'src/assets/images/canned-food/canned-vegetables/ba10.jpg';
import ba11 from 'src/assets/images/canned-food/canned-vegetables/ba11.jpg';
import ba12 from 'src/assets/images/canned-food/canned-vegetables/ba12.jpg';
import ba13 from 'src/assets/images/canned-food/canned-vegetables/ba13.jpg';
import ba14 from 'src/assets/images/canned-food/canned-vegetables/ba14.jpg';
import ba15 from 'src/assets/images/canned-food/canned-vegetables/ba15.jpg';
import ba16 from 'src/assets/images/canned-food/canned-vegetables/ba16.jpg';
import ba17 from 'src/assets/images/canned-food/canned-vegetables/ba17.jpg';
import ba18 from 'src/assets/images/canned-food/canned-vegetables/ba18.jpg';
import ba19 from 'src/assets/images/canned-food/canned-vegetables/ba19.jpg';
import ba20 from 'src/assets/images/canned-food/canned-vegetables/ba20.jpg';
import ba21 from 'src/assets/images/canned-food/canned-vegetables/ba21.jpg';
import ba22 from 'src/assets/images/canned-food/canned-vegetables/ba22.jpg';

// canned food marinades
import ma1 from 'src/assets/images/canned-food/marinades/ma1.jpg';
import ma2 from 'src/assets/images/canned-food/marinades/ma2.jpg';
import ma3 from 'src/assets/images/canned-food/marinades/ma3.jpg';
import ma4 from 'src/assets/images/canned-food/marinades/ma4.jpg';
import ma5 from 'src/assets/images/canned-food/marinades/ma5.jpg';
import ma6 from 'src/assets/images/canned-food/marinades/ma6.jpg';
import ma7 from 'src/assets/images/canned-food/marinades/ma7.jpg';
import ma8 from 'src/assets/images/canned-food/marinades/ma8.jpg';
import ma9 from 'src/assets/images/canned-food/marinades/ma9.jpg';
import ma10 from 'src/assets/images/canned-food/marinades/ma10.jpg';
import ma11 from 'src/assets/images/canned-food/marinades/ma11.jpg';
import ma12 from 'src/assets/images/canned-food/marinades/ma12.jpg';
import ma13 from 'src/assets/images/canned-food/marinades/ma13.jpg';
import ma14 from 'src/assets/images/canned-food/marinades/ma14.jpg';
import ma15 from 'src/assets/images/canned-food/marinades/ma15.jpg';
import ma16 from 'src/assets/images/canned-food/marinades/ma16.jpg';
import ma17 from 'src/assets/images/canned-food/marinades/ma17.jpg';

// canned food sauces
import sa1 from 'src/assets/images/canned-food/sauces/sa1.jpg';
import sa2 from 'src/assets/images/canned-food/sauces/sa2.jpg';
import sa3 from 'src/assets/images/canned-food/sauces/sa3.jpg';
import sa4 from 'src/assets/images/canned-food/sauces/sa4.jpg';

// canned food preserves
import mu1 from 'src/assets/images/canned-food/preserves/mu1.jpg';
import mu2 from 'src/assets/images/canned-food/preserves/mu2.jpg';
import mu3 from 'src/assets/images/canned-food/preserves/mu3.jpg';
import mu4 from 'src/assets/images/canned-food/preserves/mu4.jpg';
import mu5 from 'src/assets/images/canned-food/preserves/mu5.jpg';
import mu6 from 'src/assets/images/canned-food/preserves/mu6.jpg';
import mu7 from 'src/assets/images/canned-food/preserves/mu7.jpg';
import mu8 from 'src/assets/images/canned-food/preserves/mu8.jpg';
import mu9 from 'src/assets/images/canned-food/preserves/mu9.jpg';
import mu10 from 'src/assets/images/canned-food/preserves/mu10.jpg';
import mu11 from 'src/assets/images/canned-food/preserves/mu11.jpg';
import mu12 from 'src/assets/images/canned-food/preserves/mu12.jpg';
import mu13 from 'src/assets/images/canned-food/preserves/mu13.jpg';
import mu14 from 'src/assets/images/canned-food/preserves/mu14.jpg';
import mu15 from 'src/assets/images/canned-food/preserves/mu15.jpg';
import mu16 from 'src/assets/images/canned-food/preserves/mu16.jpg';
import mu17 from 'src/assets/images/canned-food/preserves/mu17.jpg';
import mu18 from 'src/assets/images/canned-food/preserves/mu18.jpg';
import mu19 from 'src/assets/images/canned-food/preserves/mu19.jpg';
import mu20 from 'src/assets/images/canned-food/preserves/mu20.jpg';
import mu21 from 'src/assets/images/canned-food/preserves/mu21.jpg';

// canned food jams
import ja1 from 'src/assets/images/canned-food/jams/ja1.jpg';
import ja2 from 'src/assets/images/canned-food/jams/ja2.jpg';
import ja3 from 'src/assets/images/canned-food/jams/ja3.jpg';
import ja4 from 'src/assets/images/canned-food/jams/ja4.jpg';
import ja5 from 'src/assets/images/canned-food/jams/ja5.jpg';
import ja6 from 'src/assets/images/canned-food/jams/ja6.jpg';
import ja7 from 'src/assets/images/canned-food/jams/ja7.jpg';

// canned food compotes and juices
import ko1 from 'src/assets/images/canned-food/compotes-and-juices/ko1.jpg';
import ko2 from 'src/assets/images/canned-food/compotes-and-juices/ko2.jpg';
import ko3 from 'src/assets/images/canned-food/compotes-and-juices/ko3.jpg';
import ko4 from 'src/assets/images/canned-food/compotes-and-juices/ko4.jpg';
import ko5 from 'src/assets/images/canned-food/compotes-and-juices/ko5.jpg';
import ko6 from 'src/assets/images/canned-food/compotes-and-juices/ko6.jpg';
import ko7 from 'src/assets/images/canned-food/compotes-and-juices/ko7.jpg';
import ko8 from 'src/assets/images/canned-food/compotes-and-juices/ko8.jpg';
import ko9 from 'src/assets/images/canned-food/compotes-and-juices/ko9.jpg';
import ko10 from 'src/assets/images/canned-food/compotes-and-juices/ko10.jpg';
import ko11 from 'src/assets/images/canned-food/compotes-and-juices/ko11.jpg';
import ko12 from 'src/assets/images/canned-food/compotes-and-juices/ko12.jpg';
import ko13 from 'src/assets/images/canned-food/compotes-and-juices/ko13.jpg';
import ko14 from 'src/assets/images/canned-food/compotes-and-juices/ko14.jpg';
import ko15 from 'src/assets/images/canned-food/compotes-and-juices/ko15.jpg';
import ko16 from 'src/assets/images/canned-food/compotes-and-juices/ko16.jpg';
import ko17 from 'src/assets/images/canned-food/compotes-and-juices/ko17.jpg';
import ko18 from 'src/assets/images/canned-food/compotes-and-juices/ko18.jpg';
import ko19 from 'src/assets/images/canned-food/compotes-and-juices/ko19.jpg';
import ko20 from 'src/assets/images/canned-food/compotes-and-juices/ko20.jpg';
import ko21 from 'src/assets/images/canned-food/compotes-and-juices/ko21.jpg';
import ko22 from 'src/assets/images/canned-food/compotes-and-juices/ko22.jpg';

const ProductList = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const { product, brand } = useParams();

  const isLightMode = product === 'canned-food';

  const navigate = useNavigate();

  const { t } = useTranslation('translation');

  const wineTypes = ['gagat', 'kenats'];
  const vodkaTypes = ['aged', 'traditional'];
  const cannedFoodTypes = ['canned-vegetables', 'marinades', 'sauces', 'preserves', 'jams', 'compotes-and-juices'];

  const productsData = getProducts({ product, t });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (
      !(product === 'wine' && wineTypes.includes(brand)) &&
      !(product === 'vodka' && vodkaTypes.includes(brand)) &&
      !(product === 'canned-food' && cannedFoodTypes.includes(brand))
    ) {
      navigate('/');
    }
  }, [product]);

  const json: any = {
    wine: {
      gagat: [
        { img: g1, title: t('wine.gagat.g1'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g2, title: t('wine.gagat.g2'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g3, title: t('wine.gagat.g3'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g4, title: t('wine.gagat.g4'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g5, title: t('wine.gagat.g5'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g6, title: t('wine.gagat.g6'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g14, title: t('wine.gagat.g14'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g15, title: t('wine.gagat.g15'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g7, title: t('wine.gagat.g7'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g8, title: t('wine.gagat.g8'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g9, title: t('wine.gagat.g9'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g10, title: t('wine.gagat.g10'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g11, title: t('wine.gagat.g11'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g12, title: t('wine.gagat.g12'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g13, title: t('wine.gagat.g13'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g16, title: t('wine.gagat.g16'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g17, title: t('wine.gagat.g17'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: g18, title: t('wine.gagat.g18'), subTitle: t('wineVolume', { v: 0.75 }) },
      ],
      kenats: [
        { img: k1, title: t('wine.kenats.k1'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k2, title: t('wine.kenats.k2'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k3, title: t('wine.kenats.k3'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k4, title: t('wine.kenats.k4'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k5, title: t('wine.kenats.k5'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k6, title: t('wine.kenats.k6'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k15, title: t('wine.kenats.k15'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k14, title: t('wine.kenats.k14'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k7, title: t('wine.kenats.k7'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k8, title: t('wine.kenats.k8'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k9, title: t('wine.kenats.k9'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k10, title: t('wine.kenats.k10'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k11, title: t('wine.kenats.k11'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k12, title: t('wine.kenats.k12'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k13, title: t('wine.kenats.k13'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k16, title: t('wine.kenats.k16'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k17, title: t('wine.kenats.k17'), subTitle: t('wineVolume', { v: 0.75 }) },
        { img: k18, title: t('wine.kenats.k18'), subTitle: t('wineVolume', { v: 0.75 }) },
      ]
    },
    vodka: {
      aged: [
        { img: a1, title: t('vodka.aged.a1'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: a2, title: t('vodka.aged.a2'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: a3, title: t('vodka.aged.a3'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: a4, title: t('vodka.aged.a4'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: a5, title: t('vodka.aged.a5'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: a6, title: t('vodka.aged.a6'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
      ],
      traditional: [
        { img: t1, title: t('vodka.traditional.t1'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: t2, title: t('vodka.traditional.t2'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: t3, title: t('vodka.traditional.t3'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: t4, title: t('vodka.traditional.t4'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: t5, title: t('vodka.traditional.t5'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
        { img: t6, title: t('vodka.traditional.t6'), subTitle: t('vodkaVolume', { v1: 0.25, v2: 0.5, v3: 0.75 }) },
      ]
    },
    'canned-food': {
      'canned-vegetables': [
        { img: ba1, title: t('canned.cannedVegetables.ba1'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba2, title: t('canned.cannedVegetables.ba2'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba3, title: t('canned.cannedVegetables.ba3'), subTitle: t('cannedFoodVolume', { v: 550 }) },
        { img: ba4, title: t('canned.cannedVegetables.ba4'), subTitle: t('cannedFoodVolume', { v: 550 }) },
        { img: ba5, title: t('canned.cannedVegetables.ba5'), subTitle: t('cannedFoodVolume', { v: 280 }) },
        { img: ba6, title: t('canned.cannedVegetables.ba6'), subTitle: t('cannedFoodVolume', { v: 280 }) },
        { img: ba7, title: t('canned.cannedVegetables.ba7'), subTitle: t('cannedFoodVolume', { v: 750 }) },
        { img: ba8, title: t('canned.cannedVegetables.ba8'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ba9, title: t('canned.cannedVegetables.ba9'), subTitle: t('cannedFoodVolume', { v: 520 }) },
        { img: ba10, title: t('canned.cannedVegetables.ba10'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba11, title: t('canned.cannedVegetables.ba11'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba12, title: t('canned.cannedVegetables.ba12'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ba13, title: t('canned.cannedVegetables.ba13'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba14, title: t('canned.cannedVegetables.ba14'), subTitle: t('cannedFoodVolume', { v: 490 }) },
        { img: ba15, title: t('canned.cannedVegetables.ba15'), subTitle: t('cannedFoodVolume', { v: 900 }) },
        { img: ba16, title: t('canned.cannedVegetables.ba16'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ba17, title: t('canned.cannedVegetables.ba17'), subTitle: t('cannedFoodVolume', { v: 900 }) },
        { img: ba18, title: t('canned.cannedVegetables.ba18'), subTitle: t('cannedFoodVolume', { v: 740 }) },
        { img: ba19, title: t('canned.cannedVegetables.ba19'), subTitle: t('cannedFoodVolume', { v: 750 }) },
        { img: ba20, title: t('canned.cannedVegetables.ba20'), subTitle: t('cannedFoodVolume', { v: 290 }) },
        { img: ba21, title: t('canned.cannedVegetables.ba21'), subTitle: t('cannedFoodVolume', { v: 530 }) },
        { img: ba22, title: t('canned.cannedVegetables.ba22'), subTitle: t('cannedFoodVolume', { v: 530 }) },
      ],
      marinades: [
        { img: ma1, title: t('canned.marinades.ma1'), subTitle: t('cannedFoodVolume', { v: 900 }) },
        { img: ma2, title: t('canned.marinades.ma2'), subTitle: t('cannedFoodVolume', { v: 740 }) },
        { img: ma3, title: t('canned.marinades.ma3'), subTitle: t('cannedFoodVolume', { v: 500 }) },
        { img: ma4, title: t('canned.marinades.ma4'), subTitle: t('cannedFoodVolume', { v: 900 }) },
        { img: ma5, title: t('canned.marinades.ma5'), subTitle: t('cannedFoodVolume', { v: 900 }) },
        { img: ma6, title: t('canned.marinades.ma6'), subTitle: t('cannedFoodVolume', { v: 670 }) },
        { img: ma7, title: t('canned.marinades.ma7'), subTitle: t('cannedFoodVolume', { v: 490 }) },
        { img: ma8, title: t('canned.marinades.ma8'), subTitle: t('cannedFoodVolume', { v: 750 }) },
        { img: ma9, title: t('canned.marinades.ma9'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ma10, title: t('canned.marinades.ma10'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ma11, title: t('canned.marinades.ma11'), subTitle: t('cannedFoodVolume', { v: 520 }) },
        { img: ma12, title: t('canned.marinades.ma12'), subTitle: t('cannedFoodVolume', { v: 750 }) },
        { img: ma13, title: t('canned.marinades.ma13'), subTitle: t('cannedFoodVolume', { v: 520 }) },
        { img: ma14, title: t('canned.marinades.ma14'), subTitle: t('cannedFoodVolume', { v: 540 }) },
        { img: ma15, title: t('canned.marinades.ma15'), subTitle: t('cannedFoodVolume', { v: 780 }) },
        { img: ma16, title: t('canned.marinades.ma16'), subTitle: t('cannedFoodVolume', { v: 720 }) },
        { img: ma17, title: t('canned.marinades.ma17'), subTitle: t('cannedFoodVolume', { v: 520 }) },
      ],
      sauces: [
        { img: sa1, title: t('canned.sauces.sa1'), subTitle: t('cannedFoodVolume', { v: 400 }) },
        { img: sa2, title: t('canned.sauces.sa2'), subTitle: t('cannedFoodVolume', { v: 420 }) },
        { img: sa3, title: t('canned.sauces.sa3'), subTitle: t('cannedFoodVolume', { v: 340 }) },
        { img: sa4, title: t('canned.sauces.sa4'), subTitle: t('cannedFoodVolume', { v: 340 }) },
      ],
      preserves: [
        { img: mu1, title: t('canned.preserves.mu1'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu2, title: t('canned.preserves.mu2'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu3, title: t('canned.preserves.mu3'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu4, title: t('canned.preserves.mu4'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu5, title: t('canned.preserves.mu5'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu6, title: t('canned.preserves.mu6'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu7, title: t('canned.preserves.mu7'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu8, title: t('canned.preserves.mu8'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu9, title: t('canned.preserves.mu9'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu10, title: t('canned.preserves.mu10'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu11, title: t('canned.preserves.mu11'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu12, title: t('canned.preserves.mu12'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu13, title: t('canned.preserves.mu13'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu14, title: t('canned.preserves.mu14'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu15, title: t('canned.preserves.mu15'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu16, title: t('canned.preserves.mu16'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu17, title: t('canned.preserves.mu17'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu18, title: t('canned.preserves.mu18'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu19, title: t('canned.preserves.mu19'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu20, title: t('canned.preserves.mu20'), subTitle: t('cannedFoodVolume', { v: 450 }) },
        { img: mu21, title: t('canned.preserves.mu21'), subTitle: t('cannedFoodVolume', { v: 450 }) },
      ],
      jams: [
        { img: ja1, title: t('canned.jams.ja1'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja2, title: t('canned.jams.ja2'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja3, title: t('canned.jams.ja3'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja4, title: t('canned.jams.ja4'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja5, title: t('canned.jams.ja5'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja6, title: t('canned.jams.ja6'), subTitle: t('cannedFoodVolume', { v: 300 }) },
        { img: ja7, title: t('canned.jams.ja7'), subTitle: t('cannedFoodVolume', { v: 300 }) },
      ],
      'compotes-and-juices': [
        { img: ko1, title: t('canned.compotesAndJuices.ko1'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko2, title: t('canned.compotesAndJuices.ko2'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko3, title: t('canned.compotesAndJuices.ko3'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko4, title: t('canned.compotesAndJuices.ko4'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko5, title: t('canned.compotesAndJuices.ko5'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko6, title: t('canned.compotesAndJuices.ko6'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko7, title: t('canned.compotesAndJuices.ko7'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko8, title: t('canned.compotesAndJuices.ko8'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko9, title: t('canned.compotesAndJuices.ko9'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko10, title: t('canned.compotesAndJuices.ko10'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko11, title: t('canned.compotesAndJuices.ko11'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko12, title: t('canned.compotesAndJuices.ko12'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko13, title: t('canned.compotesAndJuices.ko13'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko14, title: t('canned.compotesAndJuices.ko14'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko15, title: t('canned.compotesAndJuices.ko15'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko16, title: t('canned.compotesAndJuices.ko16'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko17, title: t('canned.compotesAndJuices.ko17'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko18, title: t('canned.compotesAndJuices.ko18'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko19, title: t('canned.compotesAndJuices.ko19'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko20, title: t('canned.compotesAndJuices.ko20'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko21, title: t('canned.compotesAndJuices.ko21'), subTitle: t('compotesVolume', { v: 1000 }) },
        { img: ko22, title: t('canned.compotesAndJuices.ko22'), subTitle: t('compotesVolume', { v: 310 }) },
      ]
    }
  };

  const list = json[product][brand];

  const _slideLeft = () => {
    setActiveIndex(activeIndex === 0 ? list.length - 1 : activeIndex - 1);
  };
  const _slideRight = () => {
    setActiveIndex(activeIndex === list.length - 1 ? 0 : activeIndex + 1);
  };

  const _onKeyDown = (e: any) => {
    switch (e.which) {
      case 37: {
        _slideLeft();
        break;
      }
      case 39: {
        _slideRight();
        break;
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', _onKeyDown);

    return () => {
      window.removeEventListener('keydown', _onKeyDown);
    };
  }, [_onKeyDown]);

  const _renderModal = () => {
    const openData = json[product][brand][activeIndex];

    return (
      <Modal isShow={activeIndex !== null} width={1200} onClose={() => setActiveIndex(null)} height={1200}>
        <View flex aCenter height="100%" padding={{ bottom: 16 }}>
          <View cursor="pointer" padding={{ horizontal: 16 }} onClick={_slideLeft}>
            <Icon name="left" color="white" size={20} />
          </View>
          <View flex column jCenter aCenter height="100%" grow={1} bgColor={isLightMode && 'white'}>
            <View width="100%" height="80vh" grow={1} bg={`center/contain url(${openData?.img}) no-repeat`} />
            <Text center size={20} color={isLightMode ? 'green' : 'white'} typo="20/white" bold>{openData?.title}</Text>
            <Text center size={16} color={isLightMode ? 'green' : 'white'} typo="16/white" bold margin={{ bottom: 10 }}>{openData?.subTitle}</Text>
          </View>
          <View cursor="pointer" padding={{ horizontal: 16 }} onClick={_slideRight}>
            <Icon name="right" color="white" size={20} />
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View
      flex
      column
      minH="100vh"
      padding_m={{ top: 70 }}
      padding_s={{ top: 16 }}
      bg={!isLightMode && `center/cover url(${homeBg}) no-repeat fixed`}
      bgColor={isLightMode && 'backgroundLight'}
    >
      <Header isLightMode={isLightMode} />

      <View grow={1} flex column aCenter jCenter padding={{ vertical: 64 }}>
        <View flex flexWrap jCenter gap={24} padding={{ bottom: 18, horizontal: 12 }}>
          {productsData.list.map((item: any) => (
            <View cursor="pointer" onClick={() => navigate(item.navigate)}>
              <Text size={16} whiteSpace="nowrap" color={isLightMode ? 'green' : 'white'} decoration={item.navigate.endsWith(brand) && 'underline'}>
                {item.label}
              </Text>
            </View>
          ))}
        </View>
        <View flex evenly flexWrap width_m={1200}>
          {list?.map((item: any, index: number) => (
            <View 
              flex
              jEnd
              column
              aCenter
              width_m={320}
              maxW_s="100%"
              padding={16}
              width_s="100%"
              cursor="pointer"
              onClick={() => setActiveIndex(index)}
              margin_s={{ bottom: 8, horizontal: 8 }}
              margin_m={{ horizontal: 16, bottom: 16 }}
              bgColor={isLightMode ? 'white' : 'rgba(255, 255, 255, 0.1)'} 
            >
              <Image src={item.img} width="25vh" />
              <Text center size={16} color={isLightMode ? 'green' : 'white'} bold>{item.title}</Text>
              <Text center size={14} color={isLightMode ? 'green' : 'white'} bold>{item.subTitle}</Text>
            </View>
          ))}
        </View>
      </View>

      <Footer isLightMode={isLightMode} />

      {_renderModal()}
    </View>
  );
};

export default ProductList;
