/** @format */

import styled from 'styled-components';

import { colors } from 'src/styles/theme';

interface Props {
  width: number;
  height: number;
  color: string;
}

const SvgStyled = styled.svg`
  ${({ color, width, height }: Props) => `
    width: ${width}px;
    min-width: ${width}px;
    height: ${height}px;
    min-height: ${height}px;
    fill: ${colors[color] || color};
  `}
`;

export default SvgStyled;
