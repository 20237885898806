import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { View, Header, Image, Text, Button, Icon, Footer } from 'src/components';

// assets
import homeBg from 'src/assets/images/homeBg.jpg';
import kenatsBg from 'src/assets/images/kenatsBg.png';
import armeniabg from 'src/assets/images/armeniabg.jpg';
import gagatbotle from 'src/assets/images/gagatbotle.png';
import vodka from 'src/assets/images/vodka.png';
import vegetable from 'src/assets/images/vegetable.png';
import aboutBg from 'src/assets/images/aboutBg.jpg';

import homeWine1 from 'src/assets/images/homeWine1.png';
import homeWine2 from 'src/assets/images/homeWine2.png';
import homeWine3 from 'src/assets/images/homeWine3.png';

const Home = (): JSX.Element => {
  const [activeBottle, setActiveBottle] = React.useState(0);

  const navigate = useNavigate();

  const { t } = useTranslation('translation');

  const bottles = [
    homeWine1,
    homeWine2,
    homeWine3
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    let activeBottle: number = 0;
    setInterval(() => {
      const newActiveBottle = activeBottle === bottles.length - 1 ? 0 : activeBottle + 1;

      activeBottle = newActiveBottle
      setActiveBottle(newActiveBottle);
    }, 4000);
  }, []);

  return (
    <View>
      <Header />

      <View flex aCenter jCenter height="100vh" bg={`center/cover url(${homeBg}) no-repeat`}>
        <View flex aCenter display_s="none">
          <View width={390} flex jEnd>
            <Text typo="42/white">
              {t('welcomeTo')}
            </Text>
          </View>
          <View margin={{ horizontal: -30 }}>
            <Image src={bottles[activeBottle]} width="30vh" />
          </View>
          <View width={390}>
            <Text  typo="42/white">
              {t('kenatsGroup')}
            </Text>
          </View>
        </View>
        <View flex column jCenter display_m="none">
          <View width={320} flex column aCenter>
            <Text typo="28/white">
              {t('welcomeTo')}
            </Text>
            <Text  typo="28/white">
              {t('kenatsGroup')}
            </Text>
          </View>
          <View flex jCenter>
            <Image src={bottles[activeBottle]} width="25vh" />
          </View>
        </View>
      </View>

      <View id="about-us" flex jCenter aCenter bg={`center/cover url(${aboutBg}) no-repeat`} padding_m={{ vertical: 128 }} padding_s={{ horizontal: 16, vertical: 64 }}>
        <View flex aCenter width_m={1200}>
          <View width_m="60%" >
            <Text center_s typo="32/white">{t('aboutUs')}</Text>
            <Text typo="16/white" margin={{ top: 32 }} lineHeight={30}>{t('aboutUsInfo')}</Text>
          </View>
        </View>
      </View>

      <View flex column bgColor="black" padding_m={{ vertical: 64 }} padding_s={{ horizontal: 16, vertical: 32 }}>
        <View flex jCenter margin_m={{ bottom: -150 }}>
          <View flex jEnd width_m={1200}>
            <Text center_s typo="32/white">{t('kenatsWine')}</Text>
          </View>
        </View>
        <View display_s="none">
          <Image src={kenatsBg} width={1200} />
        </View>
        <View flex jCenter margin_s={{ top: 32 }}>
          <View flex aEnd between aCenter_s columnReverse_s width={1200} margin_m={{ top: -100 }}>
            <Button label={t('learnMore')} minWidth={230} onClick={() => navigate('/products/wine/kenats')} />
            <View width_m={600} margin_s={{ bottom: 16 }}>
              <Text typo="16/white" lineHeight={30} textAlign_m="right">{t('kenatsWineInfo')}</Text>
            </View>
          </View>
        </View>
        <View display_m="none">
          <Image src={kenatsBg} width="100%" />
        </View>
      </View>

      <View id="armenia" flex jCenter aCenter bg={`center/cover url(${armeniabg}) no-repeat fixed`} padding_m={{ vertical: 128 }} padding_s={{ horizontal: 16, top: 64 }}>
        <View flex between_m column_s width_m={1200}>
          <View flex column jCenter grow={1} padding_m={{ right: 200 }}>
            <Text center_s typo="32/white">{t('armenia')}</Text>
            <Text typo="16/white" margin={{ top: 32 }} lineHeight={30}>{t('armeniaInfo')}</Text>
          </View>
          <View display_s="none">
            <Icon name="map" size={350} />
          </View>
          <View flex jCenter display_m="none">
            <Icon name="map" size={280} />
          </View>
        </View>
      </View>

      <View flex jCenter bgColor="gold" height_m="50vw" padding_s={16}>
        <View flex between_m columnReverse_s width_m={1200}>
          <View padding={{ top: '13vw' }} display_s="none">
            <Image src={gagatbotle} width="29vw" />
          </View>
          <View flex jCenter margin={{ top: 16 }} display_m="none">
            <Image src={gagatbotle} width={280} />
          </View>
          <View>
            <View flex jCenter_s>
              <Icon name="gagatLogo" size={150} />
            </View>
            <View flex column aEnd_m aCenter_s grow_m={1} padding_m={{  left: 64, }} margin_m={{ top: -50 }}>
              <Text center_s typo="32/white">{t('gagatWine')}</Text>
              <Text typo="16/white" margin={{ top: 32, bottom: 64 }} lineHeight={30} textAlign_m="right">{t('gagatWineInfo')}</Text>
              <Button label={t('learnMore')} minWidth={230} onClick={() => navigate('/products/wine/gagat')} />
            </View>
          </View>
        </View>
      </View>

      <View flex jCenter bgColor="black">
        <View flex between_m column_s width_m={1200} padding_m={{ top: 64 }} padding_s={16}>
          <View padding={{ top: 64 }}>
            <View flex aEnd_m between_m column_s aCenter_s>
              <Text typo="32/white" margin={{ right: 32 }}>{t('gagatVodka')}</Text>
              <View display_s="none">
                <Icon name="hooch" width={400} height={278} />
              </View>
              <View display_m="none" margin={{ top: 16 }}>
                <Icon name="hooch" width={280} height={180} />
              </View>
            </View>
            <View flex_s column_s aCenter_s>
              <Text typo="16/white" margin={{ top: 32, bottom: 64 }} lineHeight={30}>{t('gagatVodkaInfo')}</Text>
              <Button label={t('learnMore')} minWidth={230} onClick={() => navigate('/products/vodka')} />
            </View>
          </View>
          <View padding={{ left: 32 }} display_s="none">
            <Image src={vodka} width={400} />
          </View>
          <View flex jCenter padding={{ top: 32 }} display_m="none">
            <Image src={vodka} width={280} />
          </View>
        </View>
      </View>

      <View flex jCenter bgColor="black" padding_m={{ vertical: 32 }} padding_s={16}>
        <View flex between_m aCenter width_m={1200} columnReverse_s>
          <View display_s="none">
            <Image src={vegetable} width={450} />
          </View>
          <View display_m="none" margin={{ top: 32 }}>
            <Image src={vegetable} width={280} />
          </View>
          <View flex column aEnd_m aCenter_s padding_m={{ left: 64 }}>
            <Text typo="32/white" margin={{ right: 32 }}>{t('cannedFood')}</Text>
            <Text typo="16/white" margin={{ top: 32, bottom: 64 }} lineHeight={30} textAlign_m="right">{t('cannedFoodInfo')}</Text>
            <Button label={t('learnMore')} minWidth={230} onClick={() => navigate('/products/canned-food')} />
          </View>
        </View>
      </View>

      <Footer />

    </View>
  );
};

export default Home;
