/** @format */

import styled from 'styled-components';

import {
  size,
  other,
  border,
  flexBox,
  position,
  background,
  marginPadding
} from './common';

const setStyles = (props: any) => `
  ${size(props)}
  ${other(props)}
  ${border(props)}
  ${flexBox(props)}
  ${position(props)}
  ${background(props)}
  ${marginPadding(props)}
  ${setResponsiveStyles(props)}
`;

export const setResponsiveStyles = (props: any): any => {
  const mediaQuerys: any = {
    s: '(max-width: 1360px)',
    m: '(min-width: 1360px)'
  };

  let responsiveProps = Object.entries(props).filter(prop => prop[0].includes('_'));
  if(!responsiveProps.length) return '';

  const query: any = {};

  responsiveProps.forEach(item => {
    const queryName = item[0].slice(item.indexOf('-'));
    const propName = item[0].slice(0, item.indexOf('-') - 1);

    if(!query[queryName]) query[queryName] = {};
    
    query[queryName][propName] = item[1];
  })

  return Object.entries(query).map(item => `
    @media ${mediaQuerys[item[0]]}{
      ${setStyles(item[1])}
    }
  `).join('');
};

const View = styled.div`
  ${setStyles}
  ${setResponsiveStyles}
`;

export default View;
