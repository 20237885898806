import styled from 'styled-components';

import { View } from 'src/components';

interface Props {
  error: boolean;
  disabled: boolean;
  isChecked: boolean;
}

const CheckboxContainerStyled = styled(View)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default CheckboxContainerStyled;
