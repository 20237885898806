/** @format */

import React from 'react';
import { useLayer } from 'react-laag';

// components
import { View, NowrapText } from 'src/components';
import ItemStyled from './styled/ItemStyled';

interface MenuProps {
  name?: string;
  width?: number;
  data?: Array<any>;
  valueProp?: string;
  labelProp?: string;
  placement?: any;
  filter?: boolean;
  value?: string | number;
  children?: JSX.Element;
  onSelect?: (value: any, name: string) => void;
  renderItem?: (data: any, value: string | number) => JSX.Element;
  renderContent?: (data: any) => JSX.Element;
  renderFooter?: () => JSX.Element;
}

const Menu = (props: MenuProps): JSX.Element => {
  const {
    data,
    name,
    value,
    width,
    children,
    valueProp,
    labelProp,
    placement,
    onSelect,
    renderItem,
    renderContent,
    renderFooter
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const close = () => {
    setIsOpen(false);
  };

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    onOutsideClick: close,
    placement: placement,
    overflowContainer: true,
    triggerOffset: 8,
    auto: true
  });

  const _select = (data: any) => {
    close();
    if (typeof onSelect === 'function') {
      onSelect(data, name);
    }
  };

  const _content = () => {
    if (typeof renderContent === 'function') return renderContent({ close });

    if (typeof renderItem === 'function') {
      return (
        <>
          {data.map((item: any, index: number) => (
            <ItemStyled
              key={item[valueProp]}
              index={index}
              active={item[valueProp] === value}
              onClick={() => _select(item[valueProp])}
            >
              {renderItem(item, value)}
            </ItemStyled>
          ))}
          {renderFooter && renderFooter()}
        </>
      );
    }

    return data.map((item: any, index: number) => (
      <ItemStyled
        key={item[valueProp]}
        index={index}
        active={item[valueProp] === value}
        padding={{ horizontal: 8 }}
        onClick={() => _select(item[valueProp])}
      >
        <NowrapText size={14}>{item[labelProp]}</NowrapText>
      </ItemStyled>
    ));
  };

  return (
    <View>
      <View {...triggerProps} onClick={() => setIsOpen(!isOpen)}>
        {children}
      </View>
      {isOpen &&
        renderLayer(
          <View
            zIndex={2}
            radius={4}
            width={width}
            shadow="dreamy"
            bgColor="white"
            {...layerProps}
          >
            <View>
              <View
                maxH={300}
                onClick={close}
                padding={
                  typeof renderContent === 'function'
                    ? 0
                    : { vertical: 6, horizontal: 4 }
                }
              >
                {_content()}
              </View>
            </View>
          </View>
        )}
    </View>
  );
};

Menu.defaultProps = {
  data: [],
  valueProp: 'name',
  labelProp: 'name',
  width: null,
  filter: true,
  value: null
};

export default Menu;
