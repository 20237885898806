/** @format */

import React from 'react';

import { Icon, View, Text } from 'src/components';

import ButtonStyled from './styled/ButtonStyled';
import SpinnerStyled from './styled/SpinnerStyled';

interface ButtonProps {
  label?: string;
  minWidth: number;
  disabled: boolean;
  isLoading: boolean;
  onClick?: () => void;
  isDarkMode?: boolean;
}

function Button(props: ButtonProps): JSX.Element {
  const {
    label,
    minWidth,
    isLoading,
    disabled,
    onClick,
    isDarkMode = true,
  } = props;

  const _clickHandler = () => {
    if (typeof onClick === 'function' && !disabled && !isLoading) {
      onClick();
    }
  };

  return (
    <ButtonStyled minWidth={minWidth} onClick={_clickHandler} isDarkMode={isDarkMode}>
      {isLoading ? (
        <View>
        <SpinnerStyled>
          <Icon name="loading" size={20} color="white" />
        </SpinnerStyled>
        </View>
      ) : label}
    </ButtonStyled>
  );
}

export default Button;
