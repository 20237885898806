/** @format */

import styled from 'styled-components';

interface Props {
  width?: number;
  height?: number;
}

const Image = styled.img`
  ${({ width, height }: Props) =>
    `height: ${typeof height === 'number' ? `${height}px` : height};
      width: ${typeof width === 'number' ? `${width}px` : width};`}
`;

export default Image;
