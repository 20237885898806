import React from 'react';
import { useTranslation } from 'react-i18next';

import { View, Header, Footer, Text, Modal, Icon } from 'src/components'; 

// assets
import homeBg from 'src/assets/images/homeBg.jpg';

import gal1 from 'src/assets/images/gallery/gal1.jpg';
import gal2 from 'src/assets/images/gallery/gal2.jpg';
import gal3 from 'src/assets/images/gallery/gal3.jpg';
import gal4 from 'src/assets/images/gallery/gal4.jpg';
import gal5 from 'src/assets/images/gallery/gal5.jpg';
import gal6 from 'src/assets/images/gallery/gal6.jpg';

const Gallery = (): JSX.Element => {
  const { t } = useTranslation('translation');

  const [activeIndex, setActiveIndex] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const list = [
    gal1,
    gal2,
    gal3,
    gal4,
    gal5,
    gal6
  ];

  const _slideLeft = () => {
    setActiveIndex(activeIndex === 0 ? list.length - 1 : activeIndex - 1);
  };
  const _slideRight = () => {
    setActiveIndex(activeIndex === list.length - 1 ? 0 : activeIndex + 1);
  };

  const _onKeyDown = (e: any) => {
    switch (e.which) {
      case 37: {
        _slideLeft();
        break;
      }
      case 39: {
        _slideRight();
        break;
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('keydown', _onKeyDown);

    return () => {
      window.removeEventListener('keydown', _onKeyDown);
    };
  }, [_onKeyDown]);

  return (
    <View flex column minH="100vh" bg={`center/cover url(${homeBg}) no-repeat fixed`} padding={{ top: 70 }}>
      <Header />

      <View grow={1} flex aCenter jCenter column padding_m={{ vertical: 32 }} padding_s={16}>
        <Text typo="32/white" center margin={{ bottom: 32 }}>{t('gallery')}</Text>
        <View flex evenly flexWrap width_m={1200}>
          {list.map((item: any, index: number) => (
            <View
              width_m={380}
              height_m={380}
              width_s={300}
              height_s={300}
              cursor="pointer"
              onClick={() => setActiveIndex(index)}
              margin={{ bottom: 16 }}
              bg={`center/cover url(${item}) no-repeat`}
            />
          ))}
        </View>
      </View>

      <Footer />

      <Modal width={1200} height={800} isShow={activeIndex !== null} onClose={() => setActiveIndex(null)}>
        <View flex aCenter height="100%">
          <View cursor="pointer" padding={{ horizontal: 16 }} onClick={_slideLeft}>
            <Icon name="left" color="white" size={20} />
          </View>
          <View width="100%" height="100%" bg={`center/contain url(${list[activeIndex]}) no-repeat`} />
          <View cursor="pointer" padding={{ horizontal: 16 }} onClick={_slideRight}>
            <Icon name="right" color="white" size={20} />
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default Gallery;
