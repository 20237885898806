import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// pages
import Question from 'src/pages/Question';
import Home from 'src/pages/Home';
import Contacts from 'src/pages/Contacts';
import Products from 'src/pages/Products';
import ProductList from 'src/pages/ProductList';
import Gallery from 'src/pages/Gallery';

// styles
import GlobalStyles from 'src/styles/globalStyles';

// assets
import translations from 'src/assets/translation.json';

// constants
import { ACCESS_TO_PAGE } from 'src/constants';

i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('kenats_language') || 'EN',
    resources: translations
  });

const Main = () => {
  const [access, setAccess] = React.useState(
    localStorage.getItem(ACCESS_TO_PAGE) || sessionStorage.getItem(ACCESS_TO_PAGE) ? true : false
  );

  return (
    <BrowserRouter>
      <GlobalStyles />

      <Routes>
        {access ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/products/:product" element={<Products />} />
            <Route path="/products/:product/:brand" element={<ProductList />} />
          </>
        ) : (
          <Route path="/" element={<Question setAccess={setAccess} />} />
        )}
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Main;