import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { isEmail, isMobilePhone } from 'validator';

import { View, Header, Footer, Text, Input, Button, Icon } from 'src/components';

const Contacts = (): JSX.Element => {
  const { t } = useTranslation('translation');

  const formErrorDefault: any = {
    name: null,
    email: null,
    phone: null,
    message: null
  };

  const formDataDefault: any = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  const [formData, setFormData] = React.useState(formDataDefault);
  const [formError, setFormError] = React.useState(formErrorDefault);
  const [sendLoading, setSetLoading] = React.useState(false);
  const [sended, setSended] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _updateForm = (value: string, name: string) => {
    setFormError(formErrorDefault);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const _send = async () => {
    const { name, email, phone, message } = formData;

    const errors: any = {};
    let valid = true;

    if (!name.trim()) {
      errors.name = t('nameRequired');
      valid = false;
    }

    if (!email.trim()) {
      errors.email = t('emailRequired');
      valid = false;
    } else if (!isEmail(email.trim())) {
      errors.email = t('invalidEmail');
      valid = false;
    }

    if (!phone.trim()) {
      errors.phone = t('phoneRequired');
      valid = false;
    } else if (!isMobilePhone(phone.trim(), false)) {
      errors.phone = t('invalidPhone');
      valid = false;
    }

    setFormError({
      ...formError,
      ...errors
    });

    if (!valid) return;

    setSetLoading(true);

    const response = await axios.post('https://kenats-group.herokuapp.com/sendEmail',{
      name, email, phone, message
    });

    if (response.status === 200) {
      setSended(true);
      setTimeout(() => setSended(false), 2000);
      setFormData(formDataDefault);

    }
  
    setSetLoading(false);
  };

  return (
    <View flex column minH="100vh" padding={{ top: 70 }} bgColor="black">
      <Header />

      <View grow={1} flex column aCenter padding_m={{ vertical: 32 }}>
        <Text typo="32/white" center margin_m={{ bottom: 32 }}>{t('contacts')}</Text>
        <View width_m={1200} padding_s={16}>
          <View flex columnReverse_s>
            <View width_m="35%" margin={{ right: 32 }}>
              <View>
                <Input label={t('name') + ' *'} name="name" value={formData.name} error={formError.name} onChange={_updateForm}  />
              </View>
              <View margin={{ top: 10 }}>
                <Input label={t('email') + ' *'} name="email" value={formData.email} error={formError.email} onChange={_updateForm} />
              </View>
              <View margin={{ top: 10 }}>
                <Input label={t('phone') + ' *'} name="phone" type="number" value={formData.phone} error={formError.phone} onChange={_updateForm} />
              </View>
              <View margin={{ top: 10 }}>
                <Input label={t('message')} name="message" multiline value={formData.message} error={formError.message} onChange={_updateForm} />
              </View>
              <View margin={{ top: 16 }} flex jEnd_m jCenter_s>
                <Button label={sended ? t('sended') : t('send')} disabled={sended} onClick={_send} isLoading={sendLoading} minWidth={200} />
              </View>
            </View>
            <View margin={{ vertical: 32 }} grow={1}>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="company" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  {t('kenatsGroupLls')}
                </Text>
              </View>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="location" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  {t('address')}
                </Text>
              </View>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="phone" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  +374 234 4 02 04
                </Text>
              </View>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="smartphone" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  +374 93 43 29 69
                </Text>
              </View>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="smartphone" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  +374 91 03 03 95
                </Text>
              </View>
              <View flex aCenter padding={{ vertical: 8 }}>
                <Icon name="email" color="white" size={30} />
                <Text typo="18/white" margin={{ left: 8 }}>
                  info@kenatsgroup.am
                </Text>
              </View>
            </View>
          </View>
          <View margin={{ top: 32 }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3063.878536157051!2d44.70577771556584!3d39.832140898907944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40153104fd78ada5%3A0xbde5e897e25e72ef!2sKENATS%20GROUP!5e0!3m2!1sru!2s!4v1644169343728!5m2!1sru!2s" width="100%" height="450" style={{ border: 'none', filter: 'invert(90%)' }} loading="lazy"></iframe>
          </View>

        </View>
      </View>

      <Footer />
    </View>
  );
};

export default Contacts;
