/** @format */

import React from 'react';

// components
import { View, Portal, Text, Icon } from '../../index';
import GlobalStyles from './styled/GlobalStyles';

type ChildrenType = (data: any) => JSX.Element | JSX.Element;

interface ModalProps {
  width: number;
  height: number;
  minW?: number;
  title?: string;
  isShow: boolean;
  hideHeader: boolean;
  onClose: () => void;
  children: ChildrenType;
}

const Modal = (props: ModalProps): JSX.Element => {
  const {
    onClose,
    title,
    width,
    height,
    isShow,
    children,
    hideHeader,
  } = props;

  const container = React.useRef(null);

  return isShow ? (
    <Portal>
      <View
        flex
        top="0"
        aCenter
        jCenter
        left="0"
        zIndex={1}
        width="100vw"
        height="100vh"
        ref={container}
        position="fixed"
        bgColor="rgba(0,0,0,0.8)"
      >
        <GlobalStyles />
        <View
          flex
          column
          // minW={558}
          radius={8}
          maxH={'100vh'}
          width={width}
          // bgColor="black"
          overflow="hidden"
          height={height}
          onClick={(e: any) => e.stopPropagation()}
        >
          {!hideHeader && (
            <View
              flex
              aCenter
              between
              borderB="1px solid"
              borderColor="borderLight"
              padding={{ left: 24, right: 16, vertical: 8 }}
            >
              <Text typo="16/grey190">{title}</Text>
              <View onClick={onClose} cursor="pointer">
                <Icon name="close" size={40} color="white" />
              </View>
            </View>
          )}
          {children}
        </View>
      </View>
    </Portal>
  ) : (
    <View />
  );
};

Modal.defaultProps = {
  closeOnClickWrapper: false,
  title: '',
};

export default Modal;
