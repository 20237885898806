import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import { View, Header, Footer, Image, Button, Text } from 'src/components';

// assets
import homeBg from 'src/assets/images/homeBg.jpg';

import kenatsWine from 'src/assets/images/kenatsWine.png';
import gagatWine from 'src/assets/images/gagatWine.png';

import traditionalVodka from 'src/assets/images/traditionalVodka.png';
import agedVodka from 'src/assets/images/agedVodka.png';

import cannedVegetables from 'src/assets/images/cannedVegetables.jpg';
import marinades from 'src/assets/images/marinades.jpg';
import sauces from 'src/assets/images/sauces.jpg';
import preserves from 'src/assets/images/preserves.jpg';
import jams from 'src/assets/images/jams.jpg';
import compotesAndJuices from 'src/assets/images/compotesAndJuices.jpg';

export const getProducts = ({ product, t }: any): any => {
  const types = {
    wine: {
      title: t('productWine'),
      list: [
        {
          img: kenatsWine,
          label: t('wineCollectionKenats'),
          navigate: '/products/wine/kenats'
        },
        {
          img: gagatWine,
          label: t('wineCollectionGagat'),
          navigate: '/products/wine/gagat'
        }
      ],
    },
    vodka: {
      title: t('fruitVodka'),
      list: [
        {
          img: traditionalVodka,
          label: t('traditionalVodkaGagat'),
          navigate: '/products/vodka/traditional'
        },
        {
          img: agedVodka,
          label: t('agedVodkaGagat'),
          navigate: '/products/vodka/aged'
        }
      ],
    },
    'canned-food': {
      title: t('cannedFood'),
      list: [
        {
          img: cannedVegetables,
          label: t('cannedVegetables'),
          navigate: '/products/canned-food/canned-vegetables'
        },
        {
          img: marinades,
          label: t('marinades'),
          navigate: '/products/canned-food/marinades'
        },
        {
          img: sauces,
          label: t('sauces'),
          navigate: '/products/canned-food/sauces'
        },
        {
          img: preserves,
          label: t('preserves'),
          navigate: '/products/canned-food/preserves'
        },
        {
          img: jams,
          label: t('jams'),
          navigate: '/products/canned-food/jams'
        },
        {
          img: compotesAndJuices,
          label: t('compotesAndJuices'),
          navigate: '/products/canned-food/compotes-and-juices'
        },
      ],
    }
  };

  // @ts-ignore
  return types[product] || {};
};

const Products = (): JSX.Element => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const { product } = useParams();

  const isLightMode = product === 'canned-food';

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!['wine', 'vodka', 'canned-food'].includes(product)) {
      navigate('/');
    }
  }, [product]);

  const data = getProducts({ product, t });

  return data ? (
    <View
      flex
      column
      minH="100vh"
      padding={{ top: 70 }}
      bgColor={isLightMode && 'backgroundLight'}
      bg={!isLightMode && `center/cover url(${homeBg}) no-repeat fixed`}
    >
      <Header isLightMode={isLightMode} />

      <View grow={1} flex aCenter jCenter column padding={{ vertical: 32 }}>
        <Text bold size={32} color={isLightMode ? 'green' : 'white'} center margin={{ bottom: 32 }}>{data.title}</Text>

        <View flex evenly column_s width_m={1200} flexWrap>
          {data.list.map((item: any, index: number) => (
            <View 
              key={index}
              flex
              column
              jEnd
              aCenter
              padding_m={16}
              padding_s={8}
              margin_s={{ bottom: 16 }}
              margin={{ bottom: 16 }}
              cursor="pointer"
              onClick={() => navigate(item.navigate)}
              bgColor={isLightMode ? 'white' : 'rgba(255, 255, 255, 0.1)'}
            >
              <Image src={item.img} width="25vh" />
              <View flex jCenter>
                <Button label={item.label} isDarkMode={!isLightMode} />
              </View>
            </View>
          ))}
        </View>
      </View>

      <Footer isLightMode={isLightMode} />
    </View>
  ) : <></>;
};

export default Products;
