import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';

import { View, Text, Menu, Icon } from 'src/components';

import LinkStyled from './styled/LinkStyled';

interface HeaderProps {
  isLightMode?: boolean;
}

const Header = ({ isLightMode }: HeaderProps): JSX.Element => {
  const { t, i18n } = useTranslation('translation');

  const navigate = useNavigate();

  const [lang, setLang] = React.useState(localStorage.getItem('kenats_language') || 'EN');
  const [isOpen, setIsOpen] = React.useState(false);
  const [openedMenuMobile, setOpenedMenuMobile] = React.useState(null);

  const _homeClick = () => {
    navigate('/');
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }, 500);
  };

  const _aboutUsClick = () => {
    navigate('/');
    setTimeout(() => {
      window.scrollTo({ top: window.innerHeight - 100, behavior: "smooth" })
    }, 500);
  };

  const menuList = [
    { label: t('home'), clickHandler: _homeClick },
    { label: t('aboutUs'), href: '/#about-us' },
    { 
      label: t('products'),
      data: [
        { name: t('productWine'), clickHandler: () => navigate('/products/wine') },
        { name: t('fruitVodka'), clickHandler: () => navigate('/products/vodka') },
        { name: t('cannedFood'), clickHandler: () => navigate('/products/canned-food') }
      ] 
    },
    { label: t('armenia'), href: '/#armenia' },
    { label: t('gallery'), clickHandler: () => navigate('/gallery') },
    { label: t('contacts'), clickHandler: () => navigate('/contacts') },
  ];

  const languagesData = [
    { name: 'EN' },
    { name: 'RU' },
    { name: 'AM' },
  ];

  const _changeLanguage = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('kenats_language', lang);
  };

  const _menuToggle = () => setIsOpen(!isOpen);

  return (
    <>
      <View 
        flex
        top="0" 
        jCenter
        aCenter 
        left="0"
        zIndex={1}
        height={70} 
        width="100%" 
        position="fixed" 
        display_s="none"
        bgColor={isLightMode ? "green" : "black"} 
      >
        <View width={1200} flex between aCenter>
          <View position="relative" top={30} cursor="pointer" onClick={_homeClick}>
            <Icon name={isLightMode ? 'logoLight': 'logo'} size={100} />
          </View>
          <View flex>
            {menuList.map((item: any) => (
              item.data ? (
                <Menu
                  data={item.data}
                  placement="bottom-start"
                  renderItem={(item: any) => (
                    <View onClick={item.clickHandler} padding={{ horizontal: 4 }} width="100%">
                      <Text typo="14/black">{item.name}</Text>
                    </View>
                  )}
                >
                  <View padding={{ horizontal: 20 }} cursor="pointer">
                    <Text typo="16/white">
                      {item.label}
                    </Text>
                  </View>
                </Menu>
              ) : item.href ? (
                <View padding={{ horizontal: 20 }} cursor="pointer">
                  <LinkStyled href={item.href}>
                    <Text typo="16/white" decoration="none">
                      {item.label}
                    </Text>
                  </LinkStyled>
                </View>
              ) : (
                <View padding={{ horizontal: 20 }} cursor="pointer" onClick={item.clickHandler}>
                  <Text typo="16/white">
                    {item.label}
                  </Text>
                </View>
              )
            ))}
            <View flex jCenter width={50} margin={{ left: 64 }}>
              <Menu data={languagesData} width={50} placement="bottom-center" onSelect={_changeLanguage} value={lang}>
                <View flex aCenter cursor="pointer">
                  <Text typo="16/white" margin={{ right: 4 }}>{lang}</Text>
                  <View margin={{ bottom: 0 }}>
                    <Icon name="expand" size={13} color="white" />
                  </View>
                </View>
              </Menu>
            </View>
          </View>
        </View>
      </View>

      <View
         top="0" 
         left="0"
         zIndex={1}
         width="100%" 
         position="fixed"
         display_m="none"
         bgColor={isLightMode ? "green" : "black"} 
         padding={{ vertical: 4, horizontal: 16 }}
      >
        <View flex between aCenter>
          <View onClick={_homeClick}>
            <Icon name={isLightMode ? 'logoLight': 'logo'} size={50} />
          </View>
          <View onClick={_menuToggle}>
            <Icon name={isOpen ? "close" : "burger"} size={35} color="white" />
          </View>
        </View>
        {isOpen && (
          <View flex aEnd between>
            <View>
              {menuList.map((item: any) => (
                <>
                  {item.href ? (
                    <View padding={{ vertical: 8 }}>
                      <LinkStyled href={item.href}>
                        <Text typo="14/white" decoration="none">
                          {item.label}
                        </Text>
                      </LinkStyled>
                    </View>
                  ) : (
                    <View
                      flex
                      aCenter
                      onClick={
                        item.data 
                          ? () => setOpenedMenuMobile(openedMenuMobile ? null : item.label)
                          : item.clickHandler
                      }
                    >
                      <Text typo="14/white" padding={{ vertical: 8 }}>
                        {item.label}
                      </Text>
                      {item.data && (
                        <View flex aCenter transform={`rotate(${openedMenuMobile === item.label ? 180 : 0}deg)`} margin={{ left: 4, bottom: 3 }}>
                          <Icon name="expand" size={13} color="white" />
                        </View>
                      )}
                    </View>
                  )}
                  {item?.data && openedMenuMobile === item.label && item.data.map((item: any) => (
                    <View onClick={item.clickHandler}>
                      <Text typo="14/white" padding={{ vertical: 4, left: 16 }}>
                        {item.name}
                      </Text>
                    </View>
                  ))}
                </>
              ))}
            </View>
            <View>
              {languagesData.map((item: any) => (
                <View padding={{ vertical: 8 }} onClick={() => _changeLanguage(item.name)}>
                  <Text typo="16/white" decoration={lang === item.name ? 'underline' : 'none'}>
                    {item.name}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default Header;
