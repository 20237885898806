/** @format */

import { colors } from 'src/styles/theme';
import { fonts, textSizes } from 'src/styles/typography';

export const addMeasure = (value: number | string, measure = 'px'): any => {
  if (typeof value === 'number') {
    return `${value}${measure}`;
  }

  return value;
};

export const marginPaddingParser = (value: any, measure = 'px'): string => {
  if (typeof value === 'number') {
    return `${value}${measure}`;
  } else if (typeof value === 'object') {
    const {
      top = 0,
      left = 0,
      right = 0,
      bottom = 0,
      vertical = 0,
      horizontal = 0
    } = value;

    return `${addMeasure(vertical || top, measure)} ${addMeasure(
      horizontal || right,
      measure
    )} ${addMeasure(vertical || bottom, measure)} ${addMeasure(
      horizontal || left,
      measure
    )}`;
  }

  return value;
};

export const parseFonts = (fonts: {
  [key: string]: { name: string; file: File };
}): string => {
  return Object.values(fonts).reduce(
    (value: string, font: any) => `
      ${value}

      @font-face {
        font-family: '${font.name}';
        src: url(${font.file});
      }
    `,
    ''
  );
};

export const parseTypography = (typo: string): string => {
  const [size = 14, color = 'defaultTextColor', font = 'regular'] = typo.split('/');

  const { fs, lh } = textSizes[size];
  const textColor = colors[color];
  const { name: fontName } = fonts[font];

  return `
    font-size: ${fs}px;
    line-height: ${lh}px;
    color: ${textColor};
    font-family: ${fontName}, sans-serif;
  `;
};
