/** @format */

import React from 'react';

// components
import { View, Text, Icon } from 'src/components';

import CheckboxStyled from './styled/CheckboxStyled';
import CheckboxContainerStyled from './styled/CheckboxContainerStyled';

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => any;
}

function Checkbox(props: CheckboxProps): JSX.Element {
  const { label, checked, onChange } = props;

  const _checkboxClick = () => {
    if (typeof onChange === 'function') {
      onChange(!checked);
    }
  };

  return (
    <CheckboxContainerStyled
      isChecked={checked}
      onClick={_checkboxClick}
    >
      <View position="relative">
        <CheckboxStyled
          isChecked={checked}
        />
        {checked && (
          <View
            flex
            aCenter
            jCenter
            top={2}
            left={2}
            width={16}
            height={16}
            position="absolute"
          >
           <Icon
              size={12}
              name="checkboxCheck"
              color="white"
            />
          </View>
        )}
      </View>
      {label && (
        <Text typo="16/white" margin={{ left: 8 }}>
          {label}
        </Text>
      )}
    </CheckboxContainerStyled>
  );
}

Checkbox.defaultProps = {
  name: '',
  label: '',
  checked: false,
  disabled: false,
  indeterminate: false
};

export default Checkbox;
