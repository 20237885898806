export const colors = {
  none: 'transparent',
  white: '#fff',
  black: '#000',
  gold: '#B0976E',
  grey: '#252525',
  danger: '#FF3838',
  green: '#00672f',
  backgroundLight: '#f5f5f5',

  wheat: '#f7ecd6'
};
