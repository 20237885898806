/** @format */

import styled from 'styled-components';

import { colors } from 'src/styles/theme';

const InputStyled = styled.input`
  outline: none;
  border: none;
  filter: none;
  flex-grow: 1;
  font-size: 14px;
  height: 38px;
  width: 100%;
  padding: 0px 8px;

  color: ${colors.white};
  background-color: ${colors.none};

  &::placeholder {
    color: ${colors.grey};
  }
  &[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export default InputStyled;
