/** @format */

// theme
import { colors } from 'src/styles/theme';
import { shadows } from 'src/styles/typography';

interface Other {
  color: string;
  shadow: string;
  cursor: string;
  opacity: string;
  display: string;
  overflow: string;
  overflowX: string;
  overflowY: string;
  boxSizing: string;
  transform: string;
  transition: string;
  hoverBg: string;
  visibility: string;
  pointerEvents: string;
  textOverflow: string;
  scrollbar: string;
}

const other = ({
  color,
  shadow,
  cursor,
  opacity,
  display,
  overflow,
  overflowX,
  overflowY,
  boxSizing,
  transform,
  transition,
  hoverBg,
  visibility,
  pointerEvents,
  textOverflow,
}: Other): string => `
  ${cursor ? `cursor: ${cursor};` : ''}
  ${opacity ? `opacity: ${opacity};` : ''}
  ${display ? `display : ${display};` : ''}
  ${overflow ? `overflow : ${overflow};` : ''}
  ${overflowX ? `overflow-x : ${overflowX};` : ''}
  ${overflowY ? `overflow-y : ${overflowY};` : ''}
  ${transform ? `transform: ${transform};` : ''}
  ${boxSizing ? `box-sizing: ${boxSizing};` : ''}
  ${transition ? `transition: ${transition};` : ''}
  ${visibility ? `visibility: ${visibility};` : ''}
  ${color ? `color: ${colors[color] ?? color};` : ''}
  ${pointerEvents ? `pointer-events: ${pointerEvents};` : ''}
  ${textOverflow ? `text-overflow: ${textOverflow};` : ''}
  ${shadow ? `box-shadow: ${shadows[shadow] || shadow};` : ''}

  &:hover {
    background-color: ${colors[hoverBg] ?? hoverBg};
  }

  outline: none;
`;

export default other;
