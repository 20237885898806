/** @format */

import { addMeasure } from 'src/utils/style';

interface Size {
  measure: string;
  minW: string | number;
  maxW: string | number;
  minH: string | number;
  maxH: string | number;
  width: string | number;
  height: string | number;
  boxSizing: string;
}

const size = ({
  minW,
  maxW,
  minH,
  maxH,
  width,
  height,
  boxSizing,
  measure
}: Size): string => `
  ${width ? `width: ${addMeasure(width, measure)};` : ''}
  ${minW ? `min-width: ${addMeasure(minW, measure)};` : ''}
  ${maxW ? `max-width: ${addMeasure(maxW, measure)};` : ''}
  ${height ? `height: ${addMeasure(height, measure)};` : ''}
  ${minH ? `min-height: ${addMeasure(minH, measure)};` : ''}
  ${maxH ? `max-height: ${addMeasure(maxH, measure)};` : ''}
  ${boxSizing ? `box-sizing: ${boxSizing};` : ''}
`;

export default size;
