/** @format */

import styled from 'styled-components';

import { View } from 'src/components';
import { colors } from 'src/styles/theme';

interface Props {
  disabled: boolean;
  focus: boolean;
  error: boolean;
}

const ContainerStyled = styled(View)`
  display: flex;
  transition: 0.1s;
  border-radius: 4px;
  align-items: center;
  border: 1px solid;
  overflow: hidden;

  ${({ disabled, focus, error }: Props) => `
    // background-color: ${disabled ? colors['grey30'] : colors.white};
    border-color: ${
      disabled
        ? colors.white
        : error
        ? colors.white
        : focus
        ? colors.white
        : colors.white
    };

    &:hover {
      ${
        !focus &&
        !error &&
        !disabled &&
        `
          border-color: ${colors.white};
        `
      }
    }
  `}
`;

export default ContainerStyled;
