/** @format */

import { marginPaddingParser } from 'src/utils/style';

interface MarginPadding {
  measure: string;
  margin: string | number | any;
  padding: string | number | any;
}

const marginPadding = ({ margin, padding, measure }: MarginPadding): string => `
  ${margin ? `margin: ${marginPaddingParser(margin, measure)};` : ''}
  ${padding ? `padding: ${marginPaddingParser(padding, measure)};` : ''}
`;

export default marginPadding;
