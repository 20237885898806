/** @format */

// theme
import { colors } from 'src/styles/theme';

import { addMeasure } from 'src/utils/style';

interface Border {
  border: string;
  emptyC: string;
  measure: string;
  borderT: string;
  borderB: string;
  borderL: string;
  borderR: string;
  captionS: string;
  borderColl: string;
  borderSpace: string;
  borderColor: string;
  borderStyle: string;
  radius: string | number;
  borderW: string | number;
}

const border = ({
  border,
  emptyC,
  radius,
  borderW,
  borderT,
  borderB,
  borderL,
  measure,
  borderR,
  captionS,
  borderColl,
  borderSpace,
  borderColor,
  borderStyle
}: Border): string => `
  ${border ? `border: ${border};` : ''}
  ${emptyC ? `empty-cells: ${emptyC};` : ''}
  ${borderT ? `border-top: ${borderT};` : ''}
  ${borderL ? `border-left: ${borderL};` : ''}
  ${borderR ? `border-right: ${borderR};` : ''}
  ${borderB ? `border-bottom: ${borderB};` : ''}
  ${captionS ? `caption-side: ${captionS};` : ''}
  ${borderStyle ? `border-style: ${borderStyle};` : ''}
  ${borderColl ? `border-collapse: ${borderColl};` : ''}
  ${borderSpace ? `border-spacing: ${borderSpace};` : ''}
  ${radius ? `border-radius: ${addMeasure(radius, measure)};` : ''}
  ${borderW ? `border-width: ${addMeasure(borderW, measure)};` : ''}
  ${borderColor ? `border-color: ${colors[borderColor] || borderColor};` : ''}
`;

export default border;
