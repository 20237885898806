/** @format */

import styled from 'styled-components';

import { View } from 'src/components';

import { colors } from 'src/styles/theme';

interface Props {
  index: number;
  active: boolean;
}

const ItemStyled = styled(View)`
  height: 36px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  align-items: center;

  transition: 0.1s;

  color: ${colors.grey130};

  ${({ active, index }: Props) => `
    background-color: ${active ? colors.themeSlight : colors.white};
    margin-top: ${index === 0 ? 0 : 2}px;

    &:hover {
      background-color: ${active ? colors.themeSlight : colors.grey10};
    }
  `}
`;

export default ItemStyled;
