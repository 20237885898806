/** @format */

import styled from 'styled-components';

import { colors } from 'src/styles/theme';

interface Props {
  minWidth: number;
  isDarkMode: boolean;
}

const mainStyles = ({ minWidth, isDarkMode }: Props): string => `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s;
  color: ${colors.white};
  min-width: ${minWidth}px;
  border: 1px solid;
  ${isDarkMode ? `border-color: ${colors.white}` : `border-color: ${colors.none};`};
  background-color: ${isDarkMode ? colors.none : colors.green};
  padding: 0 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    ${!isDarkMode ? `border-color: ${colors.green};` : `border-color: ${colors.white};`}
    ${!isDarkMode ? `color: ${colors.green};` : ''}
  }
`;

const ButtonStyled = styled.button`
  ${mainStyles}
`;

export default ButtonStyled;
