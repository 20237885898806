/** @format */

import styled from 'styled-components';

import { colors } from 'src/styles/theme';

interface Props {
  error: boolean;
  disabled: boolean;
  isChecked: boolean;
  indeterminate: boolean;
}

const CheckboxStyled = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 2px;

  transition: 0.1s;
  border: 1px solid;
  border-color: ${colors.white};
`;

export default CheckboxStyled;
