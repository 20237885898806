import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import { View, Text, Button, Icon, Checkbox } from 'src/components';

// constants
import { ACCESS_TO_PAGE } from 'src/constants';

interface QuestionProps {
  setAccess: (value: boolean) => void;
}

const Question = (props: QuestionProps): JSX.Element => {
  const { setAccess } = props;

  const { t } = useTranslation('translation');

  const [accessFail, setAccessFail] = React.useState(false);
  const [remember, setRemember] = React.useState(false);

  const _yesHandler = () => {
    if (remember) {
      localStorage.setItem(ACCESS_TO_PAGE, 'true');
    } else {
      sessionStorage.setItem(ACCESS_TO_PAGE, 'true');
    }

    setAccess(true);
  };

  const _noHandler = () => {
    setAccessFail(true);
  };

  return (
    <View flex aCenter jCenter height="100vh" bgColor="black" padding={16}>
      <View>
        <View flex jCenter>
          <Icon name="logo" size={100} />
        </View>
        {accessFail ? (
          <Text center_s typo="18/white" margin={{ top: 32 }}>
            YOU MUST BE OF LEGAL DRINKING AGE TO ENTER THIS SITE
          </Text>
        ): (
          <>
            <View flex aCenter>
              <Text center_s typo="18/white" margin={{ top: 32 }}>
                TO VISIT THIS SITE, YOU MUST BE OF LEGAL DRINKING AGE. ARE YOU AT LEAST 18 YEARS OLD
              </Text>
              <Text center_s typo="18/white" margin={{ top: 29, left: 4 }} fontFamily="auto">
                ?
              </Text>
            </View>
            <View flex jCenter margin={{ top: 32 }}>
              <Button label="Yes" minWidth={100} onClick={_yesHandler} />
              <View margin={{ left: 8 }}>
                <Button label="No" minWidth={100} onClick={_noHandler} />
              </View>
            </View>
            <View flex jCenter margin={{ top: 32 }}>
              <Checkbox label="Remember me" checked={remember} onChange={setRemember} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default Question;
