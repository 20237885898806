/** @format */

// fonts
import GillSansC from 'src/assets/fonts/GillSansC.ttf';
import GillSansB from 'src/assets/fonts/GillSansB.otf';

export const fonts: { [key: string]: { name: string; file: File } } = {
  regular: {
    name: 'Gill-Sans',
    file: GillSansC
  },
  bold: {
    name: 'Gill-Sans-bold',
    file: GillSansB
  }
};

export const textSizes: { [key: string]: { fs: number; lh: number } } = {
  12: { fs: 12, lh: 18 },
  14: { fs: 14, lh: 22 },
  16: { fs: 16, lh: 24 },
  18: { fs: 18, lh: 26 },
  20: { fs: 20, lh: 28 },
  28: { fs: 28, lh: 36 },
  32: { fs: 32, lh: 40 },
  42: { fs: 42, lh: 52 }
};

export const shadows: { [key: string]: string } = {
  minimal:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.04)', // Elevation 1
  splash:
    '0px 1px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.08)', // Elevation 2
  dreamy:
    '0px 2px 20px 0px rgba(0, 0, 0, 0.04), 0px 8px 32px 0px rgba(0, 0, 0, 0.08)', // Elevation 3
  longer:
    '0px 8px 20px 0px rgba(0, 0, 0, 0.06), 0px 24px 60px 0px rgba(0, 0, 0, 0.12)' // Elevation 4
};
