/** @format */

import styled from 'styled-components';

import { colors } from 'src/styles/theme';

const TextareaStyled = styled.textarea`
  resize: none;
  border: none;
  flex-grow: 1;
  outline: none;
  font-size: 16px;
  padding: 8px;
  border-radius: 10px;
  color: ${colors.white};
  background-color: ${colors.none};
`;

export default TextareaStyled;
