/** @format */

import styled from 'styled-components';

import { View } from 'src/components';

import { colors } from 'src/styles/theme';

import { parseTypography } from 'src/utils/style';

interface Props {
  size?: number;
  typo?: string;
  color?: string;
  line?: number;
}

const NowrapText = styled(View)`
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ size, color = 'dark', line = 1 }: Props) => `
    font-size: ${size || 14}px;
    color: ${colors[color]};

    ${
      line === 1
        ? `
          white-space: nowrap;
          text-overflow: ellipsis;
        `
        : `
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${line};
        `
    }
  `}
  ${({ typo }: Props) => (typo ? parseTypography(typo) : '')}
`;

export default NowrapText;
