/** @format */

// theme
import { colors } from 'src/styles/theme';

interface Background {
  bg: string;
  bgSize: string;
  bgClip: string;
  bgColor: string;
  bgImage: string;
  bgRepeat: string;
  bgOrigin: string;
  bgPosition: string;
  bgAttachment: string;
}

const background = ({
  bg,
  bgSize,
  bgClip,
  bgColor,
  bgImage,
  bgRepeat,
  bgOrigin,
  bgPosition,
  bgAttachment
}: Background): string => `
  ${bg ? `background: ${bg};` : ''}
  ${bgOrigin ? `background-origin: ${bgOrigin};` : ''}
  ${bgRepeat ? `background-repeat: ${bgRepeat};` : ''}
  ${bgSize ? `background-size: ${bgSize};` : ''}
  ${bgClip ? `background-clip: ${bgClip};` : ''}
  ${bgPosition ? `background-position: ${bgPosition};` : ''}
  ${bgAttachment ? `background-attachment: ${bgAttachment};` : ''}
  ${bgColor ? `background-color: ${colors[bgColor] || bgColor};` : ''}
  ${bgImage ? `background-image: url(${bgImage});` : ''}
`;

export default background;
