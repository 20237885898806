/** @format */

import React, { SyntheticEvent } from 'react';

import InputStyled from './styled/InputStyled';
import ContainerStyled from './styled/ContainerStyled';
import TextareaStyled from './styled/TextareaStyled';

import { View, Text } from 'src/components';

interface InputDefaultProps {
  name?: string;
  label?: string;
  value?: any;
  error?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  width?: string | number;
  onChange?: (value: any, name: string) => void;
}

function Input2(props: InputDefaultProps): JSX.Element {
  const {
    value,
    name,
    label,
    error,
    width,
    multiline,
    disabled,
    placeholder,
    onChange,
  } = props;

  const inputEl = React.useRef(null);
  const fieldRef = React.useRef(null);

  const [isFocus, setIsFocus] = React.useState(false);

  const listener = (e: any) => {
    if (fieldRef.current !== e.target) {
      setIsFocus(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', listener);

    return () => {
      window.removeEventListener('click', listener);
    };
  }, []);

  const _onChange = (e: SyntheticEvent): void => {
    if (typeof onChange === 'function') {
      const { value } = (e.target as unknown) as HTMLTextAreaElement;
      onChange(value, name);
    }
  };

  const _focus = () => {
    inputEl.current.focus();
    setIsFocus(true);
  };

  const _fieldClick = () => {
    if (!isFocus && !disabled) {
      _focus();
    }
  };

  return (
    <View onClick={_fieldClick} ref={fieldRef} cursor="pointer">
      {label && (
        <Text typo="14/white" padding={{ vertical: 5 }}>
          {label}
        </Text>
      )}
      <ContainerStyled
        error={error}
        width={width}
        focus={isFocus}
        disabled={disabled}
        height={multiline ? 'auto' : 40}
      >
        {multiline ? (
          <TextareaStyled
            rows={5}
            ref={inputEl}
            value={value}
            // onBlur={_blur}
            focus={isFocus}
            onFocus={_focus}
            onChange={_onChange}
          />
        ) : (
          <View flex aCenter width="100%">
            <InputStyled
              ref={inputEl}
              value={value}
              onFocus={_focus}
              disabled={disabled}
              onChange={_onChange}
              placeholder={placeholder}
            />
          </View>
        )}
      </ContainerStyled>
      {error && (
        <View flex aCenter margin={{ top: 5 }}>
          <Text typo="12/danger" margin={{ left: 5 }}>
            {error}
          </Text>
        </View>
      )}
    </View>
  );
}

Input2.defaultProps = {
  name: '',
  label: '',
  error: false,
  multiline: false,
  value: null
};

export default Input2;
