/** @format */

import React from 'react';

import icons from './icons';

import SvgStyled from './styled/SvgStyled';

interface IconProps {
  name: string;
  color?: string;
  size?: string | number;
  width?: string | number;
  height?: string | number;
}

function Icon(props: IconProps): JSX.Element {
  const { name, color, size, width, height } = props;

  return (
    <SvgStyled color={color} width={width || size} height={height || size}>
      <use xlinkHref={`#${icons[name].id}`} />
    </SvgStyled>
  );
}

Icon.defaultProps = {
  size: 16,
  color: 'black'
};

export default Icon;
