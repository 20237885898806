/** @format */

import { addMeasure } from 'src/utils/style';

interface Position {
  zIndex: string;
  measure: string;
  position: string;
  top: string | number;
  left: string | number;
  right: string | number;
  bottom: string | number;
}

const position = ({
  top,
  left,
  right,
  bottom,
  zIndex,
  measure,
  position
}: Position): string => `
  ${zIndex ? `z-index: ${zIndex};` : ''}
  ${position ? `position: ${position};` : ''}
  ${top ? `top: ${addMeasure(top, measure)};` : ''}
  ${left ? `left: ${addMeasure(left, measure)};` : ''}
  ${right ? `right: ${addMeasure(right, measure)};` : ''}
  ${bottom ? `bottom: ${addMeasure(bottom, measure)};` : ''}
`;

export default position;
