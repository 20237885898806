import React from 'react';
import { useTranslation } from 'react-i18next';

import { View, Text, Menu, Icon } from 'src/components';

interface FooterProps {
  isLightMode?: boolean;
}

const Footer = ({ isLightMode }: FooterProps): JSX.Element => {
  const { t } = useTranslation('translation');

  const textsColor = isLightMode ? 'white' : 'gold';

  return (
    <View flex jCenter bgColor={isLightMode ? 'green': 'black'}>
      <View width={1200} flex_m between aCenter borderT="1px solid" borderColor={isLightMode? 'none' : 'gold'} padding_m={{ vertical: 32 }} padding_s={8}>
        <View flex jCenter_s>
          <View cursor="pointer" onClick={() => window.open('https://www.facebook.com/kenatsgroup')}>
            <Icon name="fb" color={textsColor} size={25} />
          </View>
          <View margin={{ left: 8 }} cursor="pointer" onClick={() => window.open('https://www.instagram.com/kenats_group/')}>
            <Icon name="insta" color={textsColor} size={25} />
          </View>
        </View>
        <View flex_m>
          <View>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              {t('kenatsGroupLls')}
            </Text>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              {t('address')}
            </Text>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              info@kenatsgroup.am
            </Text>            
          </View>
          <View margin_m={{ left: 32 }}>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              +374 234 4 02 04
            </Text>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              +374 93 43 29 69
            </Text>
            <Text center_s size={14} color={textsColor} margin={{ vertical: 6 }}>
              +374 91 03 03 95
            </Text>
          </View>
        </View>
        <View>
          <Text center_s size={14} color={textsColor}>
            KENATS GROUP - All rights reserved
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Footer;
