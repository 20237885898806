/** @format */
import { addMeasure } from 'src/utils/style';
interface FlexBox {
  measure: string;
  row: boolean;
  flex: boolean;
  flexWrap: boolean;
  gap: string | number | any;
  aEnd: boolean;
  jEnd: boolean;
  sEnd: boolean;
  column: boolean;
  noWrap: boolean;
  sStart: boolean;
  aStart: boolean;
  jStart: boolean;
  around: boolean;
  evenly: boolean;
  aCenter: boolean;
  sCenter: boolean;
  sFlexEnd: boolean;
  jCenter: boolean;
  between: boolean;
  aStretch: boolean;
  rowReverse: boolean;
  grow: string | number;
  columnReverse: boolean;
  basis: string | number;
  order: string | number;
  shrink: string | number;
}

const flexBox = ({
  measure,
  row,
  flex,
  flexWrap,
  grow,
  gap,
  aEnd,
  jEnd,
  sEnd,
  order,
  basis,
  column,
  noWrap,
  sStart,
  sFlexEnd,
  shrink,
  aStart,
  jStart,
  around,
  evenly,
  aCenter,
  sCenter,
  jCenter,
  between,
  aStretch,
  rowReverse,
  columnReverse
}: FlexBox): string => `
  ${flex ? 'display: flex;' : ''}
  ${order ? `order: ${order};` : ''}
  ${flexWrap ? 'flex-wrap: wrap;' : ''}
  ${grow ? `flex-grow: ${grow};` : ''}
  ${row ? 'flex-direction: row;' : ''}
  ${gap ? `gap: ${addMeasure(gap, measure)};` : ''}
  ${noWrap ? 'flex-wrap: no-wrap;' : ''}
  ${aEnd ? 'align-items: flex-end;' : ''}
  ${sEnd ? 'align-self: self-end;' : ''}
  ${sFlexEnd ? 'align-self: flex-end;' : ''}
  ${aCenter ? 'align-items: center;' : ''}
  ${basis ? `flex-basis: ${basis};` : ''}
  ${sCenter ? 'align-self: center;' : ''}
  ${aStretch ? 'align-items: stretch;' : ''} 
  ${column ? 'flex-direction: column;' : ''}
  ${sStart ? 'align-self: self-start;' : ''}
  ${shrink ? `flex-shrink: ${shrink};` : ''}
  ${jEnd ? 'justify-content: flex-end;' : ''}
  ${aStart ? 'align-items: flex-start;' : ''}
  ${jCenter ? 'justify-content: center;' : ''}
  ${jStart ? 'justify-content: flex-start;' : ''}
  ${around ? 'justify-content: space-around;' : ''}
  ${evenly ? 'justify-content: space-evenly;' : ''}
  ${between ? 'justify-content: space-between;' : ''}
  ${rowReverse ? 'flex-direction: row-reverse;' : ''}
  ${columnReverse ? 'flex-direction: column-reverse;' : ''}
`;

export default flexBox;
