/** @format */

import styled, { keyframes } from 'styled-components';

import { View } from 'src/components';

const rotate = keyframes`
  to { transform: rotate(360deg); }
`;

const SpinnerStyled = styled(View)`
  display: flex;
  align-items: center;
  animation-name: ${rotate};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

export default SpinnerStyled;
