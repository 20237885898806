/** @format */

import { createGlobalStyle } from 'styled-components';

import { fonts } from 'src/styles/typography';

import { parseFonts } from 'src/utils/style';
import { colors } from 'src/styles/theme';

const GlobalStyles = createGlobalStyle`
  ${parseFonts(fonts)}

  * {
    box-sizing: border-box;
    letter-spacing: 1px;
    font-family: ${fonts.regular.name}, sans-serif;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background-color: ${colors.black};
  }
`;

export default GlobalStyles;
